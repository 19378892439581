import styled from 'styled-components'

export const Page = styled.main`
  display: flex;
  width: 100%;
  flex-direction: center;
  font-family: -apple-system, Roboto, sans-serif, serif;
`

export const Container = styled.div`
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

export const Title = styled.h1`
  color: #23607f;
`

export const AppTitle = styled.h2`
  color: #215e7c;
`

export const Logo = styled.img`
  max-height: 180px;
  border-radius: 20%;
  object-fit: contain;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
`

export const GooglePlayLogo = styled.img`
  height: 80px;
`

// export const
