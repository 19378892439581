import * as React from 'react'
import * as S from './index-page.style'

// markup
const IndexPage = () => {
  return (
    <S.Page>
      <S.Container>
        <S.Title>IntelliSoftware</S.Title>
        <S.AppTitle>Profit Calculator</S.AppTitle>
        <S.Logo src={'/profit-logo.png'} alt='Logo'></S.Logo>
        <a href='https://play.google.com/store/apps/details?id=com.luiz.compound_interest_app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
          <S.GooglePlayLogo
            alt='Get it on Google Play'
            src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
          />
        </a>
      </S.Container>
    </S.Page>
  )
}

export default IndexPage
